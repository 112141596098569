<template>
    <div>
        <el-row>
            <el-image class="topImg" :src="topImg"></el-image>
        </el-row>
        <el-row class="body_center body_center_contact" >
            <el-row class="title">联系我们</el-row>
            <el-row class="entitle">Contact Us</el-row>
            <el-row class="subtitle">我们在环境模拟领域工作已有十多年，曾服务过：电力电器行业、海洋工程行业、交通行业、航空航天行业、装备制造行业、
                新型与智能行业、服装产业、建筑行业、石化行业、通信行业等多家高端企业用户。在这么多年的积累中，不断创新、研发、
                整合行业最新环境模拟技术，让技术赋能于企业，为中国环境模拟技术的发展和应用，贡献一份力量。</el-row>
            <el-row class="content">
                <el-col :span="12">
                    <el-row class="tips">* 如有合作需要，请填写以下表单，我们将尽快给您回复，并为您提供最真诚的服务，谢谢您的支持。</el-row>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="form" label-position="top">
                        <el-form-item class="formitem" label="姓名" prop="name" label-width="3px">
                            <el-input v-model="ruleForm.name" placeholder="请输入真实姓名" clearable></el-input>
                        </el-form-item>
                        <el-form-item class="formitem" label="电话" prop="tele">
                            <el-input v-model="ruleForm.tele" placeholder="请输入真实电话" clearable></el-input>
                        </el-form-item>
                        <!-- <el-form-item class="formitem" label="邮箱" prop="email">
                            <el-input v-model="ruleForm.email" placeholder="请输入真实邮箱" clearable></el-input>
                        </el-form-item>  -->
                        <el-form-item class="formitemtextarea" label="留言内容" prop="content">
                            <el-input  type="textarea" v-model="ruleForm.content" placeholder="请输入留言"
                            maxlength="250"
                            show-word-limit
                            clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="button">
                            <el-button class="primarybutton" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <el-image class="contentright" :src="contentright" ></el-image>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>

<script>
import {sendEmail, contactManagement} from '@/api/contact';

export default {
    data(){
        return{
            topImg:require('@/assets/images/contact/contact.png'),
            contentright:require('@/assets/images/contact/contentright.png'),

            // 表单
            ruleForm: {
                name: '',
                tele: '',
                // email: '',
                content: '',
            },
            rules: {
                name: [
                { required: true, message: '请输入真实姓名', trigger: 'blur' }
                ],
                tele: [
                { required: true, message: '请输入真实电话', trigger: 'blur' },
                { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
                {
                    pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                    message: '请输入正确的手机号码'
                }
                ],
                // email: [
                // { required: true, message: '请输入真实邮箱', trigger: 'blur' },
                // { 
                //     pattern:/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
                //      message: "请输入正确的邮箱", }
                // ],
            },
        }
    },
    methods:{
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm);
                    sendEmail({contactCustomerName:this.ruleForm.name,
                        contactCustomerPhone:this.ruleForm.tele,
                        contactCustomerLeaveword:this.ruleForm.content
                    }).then(res => {
                        console.log(res);
                        if(res.code==200){
                            this.$message({message:res.msg,type: 'success'});
                        }else{
                            this.$message({message:res.msg,type: 'warning'});
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.topImg{
    display: block !important;
    height: 624px;
}
.el-button--primary {
    color: #FFF;
    background-color: #3278FC;
    border-color: #3278FC;
}
.body_center_contact{
    background: #fff;
    .title{
        margin:60px 0 0 0;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
    }
    .entitle{
        margin:12px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        font-weight: 400;
        color: #666666;
    }
    .subtitle{
        margin:40px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        opacity: 0.8;
        text-align: left;
    }
    .content{
        margin:101px 0 237px 0;
        .tips{
            display: flex;
            font-size: 14px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #FF4F37;
            text-align: left;
        }
        .form{
            .formitem{
                display: grid;
                width: 243px;
            }
            .formitemtextarea{
                display: grid;
                width:500px;
            }
            .button{
                display: flex;
                .primarybutton{
                    // color: #fff;
                    padding: 12px 60px;
                    // background: #3278FC;
                }
            }
        }
        .contentright{
            width: 655px;
            height: 389px;
        }
    }
}
</style>