import request from '@/util/request'

export function sendEmail(data) {
    return request({
      url: '/ysbc/applicationEmail/sendEmail',
      method: 'post',
      params:data
    })
  }

  export function contactManagement(data) {
    return request({
      url: '/ysbc/contactManagement',
      method: 'post',
      params:data
    })
  }