<template>
    <div class="zhuti">
      <div class="zhongxin">
        <el-row>
          <el-col :span="5" class="logo">
            <div class="top_zuo">
              <img class="Logo" src="@/assets/images/yiLogoHeader.svg" alt="">
            </div>
            <!-- <el-row>
              <el-col :span="4" class="logoimg">
                <el-row>
                  <img class="img" src="@/assets/images/dibulogo.png" alt="">
                </el-row>
              </el-col>
              <el-col :span="20" class="logoname">
                <el-row class="logonametitle">易盛百川</el-row>
                <el-row class="logonamesubtitle">www.ysbccloud.com</el-row>
              </el-col>
            </el-row> -->
          </el-col>
          <!-- #31342B -->
          <el-col :span="19">
            <el-menu
                :default-active="activeIndex2"
                class="el-menu-demo"
                mode="horizontal"
                :router="shifou"
                @select="handleSelect"
                background-color="#FFF"
                text-color="#000"
                active-text-color="#fff">
                <el-menu-item index="/contact">联系我们</el-menu-item>
                <el-menu-item index="/case">项目案例</el-menu-item>
                <el-menu-item index="/category">产品分类</el-menu-item>
                <el-menu-item index="/industry">应用行业</el-menu-item>
                <el-menu-item index="/home">首页</el-menu-item>

            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="dingbuxia"></div>
      <div>
        <router-view></router-view>
      </div>
      <div>
        <dibu></dibu>        
      </div>


    </div>
  </template>
  
  <script>
 import dibu from '@/component/dibu.vue'
  export default {
    components: {dibu},
    data() {
      return {
        shifou: true,
        activeIndex2: '/index'
      }
    },
    mounted() {
      if(sessionStorage.getItem('urlPath') == '' || sessionStorage.getItem('urlPath') == undefined || sessionStorage.getItem('urlPath') == null) {
        this.activeIndex2 = '/index'
      }else {
        this.activeIndex2 = sessionStorage.getItem('urlPath')
      }
    },
    methods: {
      handleSelect(key, keyPath) {
        sessionStorage.setItem('urlPath', key)
        console.log(key, keyPath);
      },
      jiazai() {
        this.nextTick(()=>{
  
          //要进行的操作
  
          })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .zhuti {
    height: 100%;
    background-color: #F7F7F7;
  }
  .zhongxin {
    position: fixed;
    top: 0;
    left: 0;
    width: 1320px;
    padding: 0 300px;
    height: 80px;
    background-color: rgb(255, 255, 255);
    z-index: 999;
    width: -webkit-fill-available;
    .logo{
      .logoimg{
        .img{
          width: 28px;
          height: 28px;
        }
      }
      .logoname{
          .logonametitle{
            font-size: 36px;
            font-family: DFPKanTingLiuW9-GB;
            font-weight: 400;
            line-height: 19px;
            color: #666666;
            // letter-spacing: 100px;
          }
          .logonamesubtitle{
            font-size: 8px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            line-height: 14px;
            color: #666666;
          }
        }
    }
  }
  .top_zuo {
    text-align: left;
    height: 80px;
    .Logo {
      width: 250px;
      height: 48px;
      margin-top: 16px;
    }
  }
  .el-menu--horizontal>.el-menu-item {
      padding: 0 !important;
      width: 160px;
      font-size: 18px;
      height: 80px !important;
      line-height: 80px !important;
  }
  a {
      display: block;
      height: 80px;
      width: 160px;
      text-align: center;
      text-decoration:none
  }
  .el-menu.el-menu--horizontal {
      border: none !important;
  }
  .el-menu--horizontal>.el-menu-item {
      float: right !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    //   background-color: #31342B !important;
      background-color: #3278FC !important;
      font-size: 18px;
      color: #FFF !important;
      font-weight: 500 !important;
    //   border-bottom: 1px solid #31342B !important;
      border-bottom: 1px solid #3278FC !important;
  }
  .dingbuxia {
    height: 80px;
  }
  </style>
  