import request from '@/util/request'

export function getCase() {
    return request({
      url: 'ysbc/projectCase/getProjectCaseByAll',
      method: 'get'
    })
  }

  // 查询应用行业名称做下拉框
  export function getCategoryName() {
    return request({
      url: '/ysbc/applicationClass/getUseApplicationClass',
      method: 'get'
    })
  }

  export function getCasebyKeyword(Keyword) {
    return request({
      url: '/ysbc/caseDetails/getCaseDetailsByName?projectCaseName='+Keyword.projectCaseName,
      method: 'post',
    //   data:Keyword
    })
  }

  export function getCasebyApplicationClassId(applicationClassId) {
    return request({
    //   url: '/ysbc/applicationClass/GetLaboratoryList',
      url: '/ysbc/applicationClass/getGetLaboratoryListCaseDetails?applicationClassId=' + applicationClassId.applicationClassId,
      method: 'post',
    //   data:applicationClassId
    })
  }