<template>
    <div>
        <el-row>
            <el-image class="topImg" :src="topImg" ></el-image>
        </el-row>
        <el-row class="body_center body_center_detail" >
            <el-row class="title">{{detail.title}}</el-row>
            <el-row class="brief">简介</el-row>
            <el-row class="briefcontent">{{detail.briefcontent}}</el-row>
            <el-row class="industry">适应行业</el-row>
            <el-row class="industrycontent">{{detail.industrycontent}}</el-row>
            <el-row class="lab">试验室功能</el-row>
            <el-row class="labcontent">{{detail.labcontent}}</el-row>
            <el-row class="tech">技术参数</el-row>
            <!-- <el-row class="techcontent">{{detail.techcontent}}</el-row> -->
            <el-row class="techcontent ql-editor" v-html="detail.techcontent" ></el-row>
            <el-row class="img">图片</el-row>
            <el-image class="imgcontent" :src="detail.imgcontent" ></el-image>
            <el-row class="gap"></el-row>
        </el-row>
    </div>
</template>

<script>
import {getCategoryDetail, getCaseDetail} from '@/api/detail';

export default {
    data(){
        return{
            topImg:require('@/assets/images/detail/detail.png'),
            // topImg:require('@/assets/images/case/case.png'),
            detail:{
                title:null,
                briefcontent:null,
                industrycontent:null,
                labcontent:null,
                techcontent:null,
                // imgcontent:require('@/assets/images/detail/detail1.png'),
                imgcontent:null
            },
            baseURL: process.env.VUE_APP_BASE_API,
        }
    },
    created(){
        this.menu();
        let name = this.$route.query.name;
        console.log({name});
        if(name=='category'){
            this.getCategoryDetailData();
        }
        if(name=='case'){
            this.getCaseDetailData();
        }
    },
    methods:{
        menu() {
            window.scrollTo(0, 0)
        },
        getCategoryDetailData(){
            getCategoryDetail(this.$route.query.id).then(res => {
                res.data.map((item,index) => {
                    this.detail = {
                        title:item.laboratoryClassCaseTitle,
                        briefcontent:item.laboratoryClassCaseSynopsis,
                        industrycontent:item.laboratoryClassCaseClass,
                        labcontent:item.laboratoryClassCaseFunction,
                        techcontent:item.laboratoryClassCaseTechnology,
                        imgcontent:this.baseURL + item.laboratoryClassCasePicture,
                        // imgcontent:require('@/assets/images/detail/detail1.png'),
                    }
                })
            })
        },
        getCaseDetailData(){
            getCaseDetail(this.$route.query.id).then(res => {
                res.data.map((item,index) => {
                    this.detail = {
                        title:item.caseDetailsTitle,
                        briefcontent:item.caseDetailsSynopsis,
                        industrycontent:item.caseDetailsClass,
                        labcontent:item.caseDetailsFunction,
                        techcontent:item.caseDetailsTechnology,
                        imgcontent:this.baseURL + item.caseDetailsPicture,
                        // imgcontent:require('@/assets/images/detail/detail1.png'),
                    }
                })
            })
        }
    }
}
</script>

<style lang="less" scoped>
.topImg{
    display: block !important;
    height: 280px;
}
.body_center_detail{
    background: #fff;
    .title{
        margin:60px 0 0 0;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
    }
    .brief{
        display: flex;
        margin:40px 0 0 0;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
        text-align: left;
    }
    .briefcontent{
        display: flex;
        margin: 20px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        text-align: left;
    }
    .industry{
        display: flex;
        margin:40px 0 0 0;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
        text-align: left;
    }
    .industrycontent{
        display: flex;
        margin: 20px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        text-align: left;
    }
    .lab{
        display: flex;
        margin:40px 0 0 0;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
        text-align: left;
    }
    .labcontent{
        display: flex;
        margin: 20px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        text-align: left;
    }
    .tech{
        display: flex;
        margin:40px 0 0 0;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
        text-align: left;
    }
    .techcontent{
        display: flex;
        margin: 20px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        text-align: left;
        white-space: pre-wrap;
    }
    .img{
        display: flex;
        margin:40px 0 0 0;
        font-size: 20px;
        font-family: Noto Sans S Chinese;
        font-weight: 700;
        color: #333333;
        text-align: left;
    }
    .imgcontent{
        display: flex;
        margin: 20px 0 0 0;
        font-size: 16px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #666666;
        text-align: left;
    }
    .gap{
        height: 80px;
    }
}
</style>