<template>
    <div>
        <el-row>
            <el-image class="topImg" :src="topImg" ></el-image>
        </el-row>
        <!-- 行业 -->
        <el-row class="body_center body_center_industry">
            <el-row class="title">应用行业</el-row>
            <el-row class="subtitle">Application Industry</el-row>
            <el-row class="switch">
                <el-button :type="buttonType1" round class="button" @click="getIndustryList(0,6)"></el-button>
                <el-button :type="buttonType2" round class="button" @click="getIndustryList(6,11)"></el-button>
            </el-row>
            <!-- <el-row class="list">
                <el-col :span="4" class="block" v-for="(block,indexBlock) in 6" :key="indexBlock">
                    <el-row class="icon">1</el-row>
                    <el-row class="iconname">2</el-row>
                    <el-row class="tag">
                        <el-col :span="12" v-for="(tag,indexTag) in 2" :key="indexTag">{{tag}}</el-col>
                    </el-row>
                    <el-row class="tagpaddingname">
                        <el-row class="tagname" v-for="(item,index) in 4" :key="index">{{item}}</el-row>
                    </el-row>

                </el-col>
            </el-row> -->
            <el-row class="list">
                <el-col :span="4" class="block"  v-for="(block,indexBlock) in industrys" :key="indexBlock"
                :class="{'industryHoverChange':indexBlock==industryHoverIndex}"
                @mouseenter.native="industryHoverIndex = indexBlock" 
                @mouseleave.native="industryHoverIndex - 1" 
                >
                    <el-row class="icon">
                        <img :src="indexBlock==industryHoverIndex ? block.iconHover : block.icon" style=""  class=img/>
                    </el-row>
                    <el-row class="iconname">{{block.iconname}}</el-row>
                    <el-row class="tag">
                        <el-col :span="12" v-for="(tag,indexTag) in block.tags" :key="indexTag">

                            <!-- <el-button @click="handleTag(indexBlock,indexTag,block,tag.tagnames,)" :type="tag.buttonType" round class="button" >{{tag.tag}}</el-button> -->
                            <el-button v-show="tag.tagnames.length>0" @click="handleTag(indexBlock,indexTag,block,tag.tagnames,)" :type="tag.buttonType" round class="button" >{{tag.tag}}</el-button>

                        </el-col>
                    </el-row>

                    
                    <el-row class="tagpaddingname" v-if="tagnamesCount==0">
                        <el-row  class="tagname" v-for="(item,index) in block.tags[0].tagnames" :key="index">{{item.tagname}}</el-row>
                    </el-row>
                </el-col>
            </el-row>

        </el-row>
    </div>
</template>

<script>
import {getIndustry,} from '@/api/industry';

export default {
    data(){
        return{
            topImg:require('@/assets/images/industry/industry.png'),
            buttonType1: 'primary',
            buttonType2: 'primary2',
            industrys:[],
            industryTags:[],
            tagnames:[
                ],
            tagnamesCount:0,
            indexTagValue:0,
            indexBlockValue:0,
            industryActive: '',
            industryHoverIndex:0,

            baseURL: process.env.VUE_APP_BASE_API,
            taga1:0,
            taga2:6,
        }
    },
    created() {
        this.menu();
        this.getIndustryList(0,6);
    },
    methods: {
    menu() {
      window.scrollTo(0, 0);
    },
    
    getIndustryList(a1,a2){
        this.taga1 = a1;
        this.taga2 = a2;
        if(a1==0){
            this.buttonType1 = 'primary';
            this.buttonType2 = 'primary2';
        }else{
            this.buttonType1 = 'primary2';
            this.buttonType2 = 'primary';
        }

        getIndustry().then(res => {
            console.log({res},a1,a2,res.data.slice(a1,a2));
            this.industrys = [];
            res.data.splice(a1,a2).map((item,index) => {

                let icon = this.baseURL + item.applicationClassPictureAddressBlack;
                let iconHover =this.baseURL + item.applicationClassPictureAddressLight;
                let iconname = item.applicationName;
                this.industrys.push(
                    {
                        icon: icon,
                        iconHover: iconHover,
                        iconname: iconname,
                        tags:[],
                    }
                )
                // console.log(1234,a1,a2,res.data,res.data.splice(a1,a2),this.industrys,item.applicationLaBleList,item.applicationLaBleList.length);

                console.log("applicationLaBleList",item.applicationLaBleList);
                if(item.applicationLaBleList.length != 0){
                    item.applicationLaBleList.map((item2,index2) => {
                        // console.log({index2});
                        let tag = item2.applicationLaBleName;
                        let buttonType = index2==0 ? 'primary' : 'primary2';
                        this.industrys[index].tags.push(
                            {
                                tag: tag,
                                buttonType:buttonType,
                                tagnames:[],
                            }
                        )
                        if(item2.applicationLaBleText != 0){
                            item2.applicationLaBleText.map((item3,index3) => {
                                this.industrys[index].tags[index2].tagnames.push({tagname:item3.applicationLaBleContent})
                            })
                        }else{
                            this.industrys[index].tags[index2].tagnames.push({tagname:null})
                        }
                    })
                }else{
                    this.industrys[index].tags.push(
                        {
                            tag: null,
                            buttonType:'primary',
                            tagnames:[],
                        }
                    )
                }
                
            })
        })
        // console.log(7777,this.industrys);

    },
    handleFirstTag(tags){
        console.log({tags},tags[0].tagnames);
        return tags[0].tagnames;
    },
    handleTag(indexBlock,indexTag,block,tagnames){
        console.log(indexBlock,indexTag,);

        getIndustry().then((res) => {
            // console.log({res});
            this.industryTags = [];
            res.data.splice(this.taga1,this.taga2).map((item,index) => {
                let icon = this.baseURL + item.applicationClassPictureAddressBlack;
                let iconHover =this.baseURL + item.applicationClassPictureAddressLight;
                let iconname = item.applicationName;
                this.industryTags.push(
                    {
                        icon: icon,
                        iconHover: iconHover,
                        iconname: iconname,
                        tags:[],
                    }
                )
                // console.log(1234,this.industryTags);

                if(item.applicationLaBleList.length != 0){
                    item.applicationLaBleList.map((item2,index2) => {
                        let tag = item2.applicationLaBleName;
                        let buttonType = index2==0 ? 'primary' : 'primary2';
                        this.industryTags[index].tags.push(
                            {
                                tag: tag,
                                buttonType:buttonType,
                                tagnames:[],
                            }
                        )
                        if(item2.applicationLaBleText != 0){
                            item2.applicationLaBleText.map((item3,index3) => {
                                this.industryTags[index].tags[index2].tagnames.push({tagname:item3.applicationLaBleContent})
                            })
                        }else{
                            this.industryTags[index].tags[index2].tagnames.push({tagname:null})
                        }
                    })
                }else{
                    this.industryTags[index].tags.push(
                        {
                            tag: null,
                            buttonType:null,
                            tagnames:[],
                        }
                    )
                }
                
            })

            console.log(1,this.industrys,indexBlock,indexTag,this.taga1,this.taga2,this.industryTags);
            this.industrys[indexBlock].tags[0].tagnames = this.industryTags[indexBlock].tags[indexTag].tagnames;
            console.log(2,this.industrys);

            this.industrys[indexBlock].tags.map((item,index) => {
                this.industrys[indexBlock].tags[index].buttonType = 'primary1';
            })
            this.industrys[indexBlock].tags[indexTag].buttonType = 'primary';
        })
    },
    mouseEnter(){
        this.industryActive = 'background: #3278FC';
        console.log(1234);
    },
    mouseLeave() {
        this.industryActive = '';
    },
  },
}
</script>

<style lang="less" scoped>
.topImg{
    width: 100%;
    height: 100%;
}
.el-button--primary{
    background: #3278FC !important;
    border-color:#fff !important;
}
.industryHoverChange{
    background: #3278FC !important;
    color: #fff;
}
.body_center_industry{
    background-color: #F4F4F4;
    // padding-top: 60px;
    // height: 77px;
    // margin-top: 200px;
    .title{
        margin: 60px 0 0 0 ;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #333333;
    }
    .subtitle{
        margin:10px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        color: #666666;
    }
    .switch{
        margin:40px 0 0 0;
        .button{
            width: 280px;
            height: 36px;
            border-radius: 18px;
        }
    }
    .list{
        margin:40px 0 80px 0;
        .block{
            height: 563px;
            background: #fff;
            border-style:solid;
            border-width: 0 1px 0 0;
            border-color: #f0f0f0;
            
            .icon{
                padding: 60px 0 0 0;
                // width: 100px;
                // height: 100px;
                .img{
                    width: 100px;height: 100px;
                }
            }
            .iconname{
                padding:10px 0 0 0;
                font-size: 20px;
                font-family: Noto Sans S Chinese;
                font-weight: 500;
                // color: #FFFFFF;
            }
            .tag{
                padding: 20px 0 0 0;
                .button{
                    // width: 80px;
                    height: 32px;
                    line-height: 0px;
                    border-radius: 20px;
                    margin: 0 0 10px 0;
                }
            }
            .tagpaddingname{
                padding:18px 0 0 0;
                .tagname{
                    padding:20px 0 0 0;
                }
            }
        }
    }
}
</style>