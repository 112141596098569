<template>
  <div class="main">
    <el-row class="top_lunbo">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in carousels" :key="index">
          <img class="lunbo" :src="baseURL + item.carouselPicture" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-row>
    <!-- 行业 -->
    <el-row class="body_center body_center_industry">
        <el-row class="title">应用行业</el-row>
        <el-row class="subtitle">Application Industry</el-row>
        <el-row class="switch">
            <el-button :type="buttonType1" round class="button" @click="getIndustryList(0,6)"></el-button>
            <el-button :type="buttonType2" round class="button" @click="getIndustryList(6,11)"></el-button>
        </el-row>
        <!-- <el-row class="list">
            <el-col :span="4" class="block" v-for="(block,indexBlock) in 6" :key="indexBlock">
                <el-row class="icon">1</el-row>
                <el-row class="iconname">2</el-row>
                <el-row class="tag">
                    <el-col :span="12" v-for="(tag,indexTag) in 2" :key="indexTag">{{tag}}</el-col>
                </el-row>
                <el-row class="tagpaddingname">
                    <el-row class="tagname" v-for="(item,index) in 4" :key="index">{{item}}</el-row>
                </el-row>

            </el-col>
        </el-row> -->
        <el-row class="list">
            <el-col :span="4" class="block"  v-for="(block,indexBlock) in industrys" :key="indexBlock"
            :class="{'industryHoverChange':indexBlock==industryHoverIndex}"
            @mouseenter.native="industryHoverIndex = indexBlock" 
            @mouseleave.native="industryHoverIndex - 1" 
            >
                <el-row class="icon">
                    <img :src="indexBlock==industryHoverIndex ? block.iconHover : block.icon" style=""  class=img/>
                </el-row>
                <el-row class="iconname">{{block.iconname}}</el-row>
                <el-row class="tag">
                    <el-col :span="12" v-for="(tag,indexTag) in block.tags" :key="indexTag">

                        <el-button v-show="tag.tagnames.length>0" @click="handleTag(indexBlock,indexTag,block,tag.tagnames,)" :type="tag.buttonType" round class="button" >{{tag.tag}}</el-button>

                    </el-col>
                </el-row>

                
                <el-row class="tagpaddingname" v-if="tagnamesCount==0">
                    <el-row  class="tagname" v-for="(item,index) in block.tags[0].tagnames" :key="index">{{item.tagname}}</el-row>
                </el-row>
            </el-col>
        </el-row>

    </el-row>
    <!-- 分类 -->
    <el-row class="body_center body_center_category" >
        <el-row class="title">产品分类</el-row>
        <el-row class="subtitle">Product Category</el-row>
        <!-- <el-row class="switch">
            <el-button type="text" v-for="(itemTag,indexTag) in categoryNames" :key="indexTag" @click="handleCategory(itemTag.applicationClassName)">{{itemTag.applicationClassName}}</el-button>
        </el-row> -->
        <el-row :gutter="20"  class="list">
            <el-col :span="6" v-for="(block,indexBlock) in categorys" :key="indexBlock" class="block"
            :class="{'categoryHoverChange':indexBlock==categoryHoverIndex}"
            @mouseenter.native="categoryHoverIndex = indexBlock" 
            @mouseleave.native="categoryHoverIndex - 1" 
            >
                <a @click="handleGotoDetail(block.id)">
                    <el-row :style="{overflow:indexBlock==categoryHoverIndex?'hidden':'1'}">
                        <el-image :src="block.img" class="img" fit="fill" :style="{transform: indexBlock==categoryHoverIndex ? 'scale(1.1)' : 'scale(1)' }"></el-image>
                    </el-row>
                    <el-row class="bottom">
                        <el-row class="bottomicon">
                            <el-col :span="20" class="categoryname" :style="{color:indexBlock==categoryHoverIndex ? '#fff' : '#333'}">
                                <el-row>{{block.industryname}}</el-row>
                            </el-col>
                            <!-- <el-col :span="4" class="icon" :style="{paddingTop:indexBlock==categoryHoverIndex ? '1.25rem' : '0.425rem'}"> -->
                            <el-col :span="4" class="icon" :style="{paddingTop:indexBlock==categoryHoverIndex ? '0.425rem' : '0.425rem'}">
                                <img :src="block.icon"  class="img"/>
                            </el-col>
                        </el-row>
                    </el-row>
                    
                    <el-row class="divider">
                        <!-- <el-button v-if="indexBlock==categoryHoverIndex" round type="primary" style="" class="button">{{block.categoryname}}</el-button> -->
                        <div v-if="indexBlock==categoryHoverIndex" ></div>
                        <el-divider v-else ></el-divider>
                    </el-row>
                </a>
            </el-col>
        </el-row>
        <el-row class="categoryfooter"></el-row>
    </el-row>
    <!-- 案例 -->
    <el-row class="body_center body_center_case">
        <el-row class="title">项目案例</el-row>
        <el-row class="subtitle">Project Case</el-row>
        <el-row class="list">
            <el-row class="block" v-for="(block,indexBlock) in cases" :key="indexBlock"
            :class="{'caseHoverChange':indexBlock==caseHoverIndex}"
            @mouseenter.native="caseHoverIndex = indexBlock" 
            @mouseleave.native="caseHoverIndex - 1" 
            >
                <a @click="handleGotoCaseDetail(block.id)" style="width:100%" v-if="indexBlock%2==0">
                    <el-col  class="left" >
                        <el-row class="lefttitle" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#333'}">{{block.title}}</el-row>
                        <el-row class="leftsubtitle" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#666'}">{{block.subtitle}}</el-row>
                    </el-col>
                    <el-col  class="middle">
                        <el-row class="topvertical"></el-row>
                        <el-row class="dot"></el-row>
                        <el-row class="footervertical"></el-row>
                    </el-col>            
                    <el-col  class="right" >
                        <el-row v-if="indexBlock==caseHoverIndex">
                            <el-col :span="16">
                                <img :src="block.img"  class="img"/>
                            </el-col>
                            <el-col :span="8">
                                <el-row class="year" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.day}}</el-row>
                                <el-row class="day" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.year}}</el-row>
                            </el-col>
                        </el-row>
                        <el-col v-else>
                            <el-row class="year" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.day}}</el-row>
                            <el-row class="day" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.year}}</el-row>
                        </el-col>
                    </el-col>
                </a>
                <a @click="handleGotoCaseDetail(block.id)" style="width:100%" v-else>
                    <el-col  class="right" >
                        <el-row v-if="indexBlock==caseHoverIndex">
                            <el-col :span="16" style="padding:0 0 0 1.25rem;">
                                <img :src="block.img"  class="img"/>
                            </el-col>
                            <el-col :span="8">
                                <el-row class="year yearelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.day}}</el-row>
                                <el-row class="day dayelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.year}}</el-row>
                            </el-col>
                        </el-row>
                        <el-col v-else>
                            <el-row class="year yearelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.day}}</el-row>
                            <el-row class="day dayelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.year}}</el-row>
                        </el-col>
                    </el-col>
                    <el-col  class="middle">
                        <el-row class="topvertical"></el-row>
                        <el-row class="dot"></el-row>
                        <el-row class="footervertical"></el-row>
                    </el-col>
                    <el-col  class="left" >
                        <el-row class="lefttitle lefttitleelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#333'}">{{block.title}}</el-row>
                        <el-row class="leftsubtitle leftsubtitleelse" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#666'}">{{block.subtitle}}</el-row>
                    </el-col>            
                    
                </a>          
            </el-row>
        </el-row>
    </el-row>
    <!-- slogan -->
    <el-row class="body_center body_center_slogan">
        <el-row :gutter="20">
            <el-col :span="8" class="slogan">
                <el-row class="missionblack">
                    <el-row class="missionimg">
                        <el-image :src="slogan.mission.icon" class="img" ></el-image>
                    </el-row>
                    <el-row class="missiontitle">{{slogan.mission.title}}</el-row>
                </el-row>
                <el-row class="missionwhite">{{slogan.mission.subtitle}}</el-row>
            </el-col>
            <el-col :span="8" class="slogan">
                <el-row class="cultureblack">
                    <el-row class="cultureimg">
                        <el-image :src="slogan.culture.icon" class="img" ></el-image>
                    </el-row>
                    <el-row class="culturetitle">{{slogan.culture.title}}</el-row>
                </el-row>
                <el-row class="culturewhite">{{slogan.culture.subtitle}}</el-row>
            </el-col>
            <el-col :span="8" class="slogan">
                <el-row class="aimblack">
                    <el-row class="aimimg">
                        <el-image :src="slogan.aim.icon" class="img" ></el-image>
                    </el-row>
                    <el-row class="aimtitle">{{slogan.aim.title}}</el-row>
                </el-row>
                <el-row class="aimwhite">{{slogan.aim.subtitle}}</el-row>
            </el-col>
        </el-row>
    </el-row>
  </div>
</template>
   
  <script>
  import {getCarousel, getIndustry, getCase, getCategoryName, getCategory} from '@/api/home';
  import moment from 'moment'

export default {
  data() {
    return {
      carousels: [
        { carouselPicture: require('@/assets/images/indexCarousel/one.png') },
        { carouselPicture: require('@/assets/images/indexCarousel/two.png') },
        { carouselPicture: require('@/assets/images/indexCarousel/three.png') },
      ],
      // 行业
      buttonType1: 'primary',
      buttonType2: 'primary2',
      industrys:[],
      tagnames:[
        ],
      tagnamesCount:0,
      indexTagValue:0,
      indexBlockValue:0,
      industryActive: '',
      industryHoverIndex:0,
      
      taga1:0,
      taga2:6,

      // 分类
      categorys:[],
      categoryHoverIndex:0,
      baseURL: process.env.VUE_APP_BASE_API,

      // 类的名字的列表
      categoryNames:[],

      // 案例
      cases:[],
      caseHoverIndex:0,

      // slogan
      slogan:{
        mission:{
            icon:require('@/assets/images/slogan/mission.png'),
            title:'我们的使命',
            subtitle:'创新不止   护航研发',
        },
        culture:{
            icon:require('@/assets/images/slogan/culture.png'),
            title:'我们的文化',
            subtitle:'本分  本职  本命',
        },
        aim:{
            icon:require('@/assets/images/slogan/aim.png'),
            title:'我们的宗旨',
            subtitle:'顾客至上   服务第一',
        },
      }
      
    }
  },
  created() {
    this.menu();
    this.getCarouselList();
    this.getIndustryList(0,6);
    // this.getCategory();
    this.getCaseList();

    this.getCategoryNameList();
    this.getCategoryList();
  },
  methods: {
    // 行业
    menu() {
      window.scrollTo(0, 0)
    },
    getCarouselList(){
        getCarousel().then(res => {
            console.log(res);
            this.carousels = res.data;
        })
    },
    getIndustryList(a1,a2){
        this.taga1 = a1;
        this.taga2 = a2;
        if(a1==0){
            this.buttonType1 = 'primary';
            this.buttonType2 = 'primary2';
        }else{
            this.buttonType1 = 'primary2';
            this.buttonType2 = 'primary';
        }

        getIndustry().then((res) => {
            console.log({res});
            this.industrys = [];
            res.data.splice(a1,a2).map((item,index) => {
                let icon = this.baseURL + item.applicationClassPictureAddressBlack;
                let iconHover =this.baseURL + item.applicationClassPictureAddressLight;
                let iconname = item.applicationName;
                this.industrys.push(
                    {
                        icon: icon,
                        iconHover: iconHover,
                        iconname: iconname,
                        tags:[],
                    }
                )
                console.log(1234,this.industrys,item.applicationLaBleList,item.applicationLaBleList.length);

                if(item.applicationLaBleList.length != 0){
                    item.applicationLaBleList.map((item2,index2) => {
                        let tag = item2.applicationLaBleName;
                        let buttonType = index2==0 ? 'primary' : 'primary2';
                        this.industrys[index].tags.push(
                            {
                                tag: tag,
                                buttonType:buttonType,
                                tagnames:[],
                            }
                        )
                        if(item2.applicationLaBleText != 0){
                            item2.applicationLaBleText.map((item3,index3) => {
                                this.industrys[index].tags[index2].tagnames.push({tagname:item3.applicationLaBleContent})
                            })
                        }else{
                            this.industrys[index].tags[index2].tagnames.push({tagname:null})
                        }
                    })
                }else{
                    this.industrys[index].tags.push(
                        {
                            tag: null,
                            buttonType:null,
                            tagnames:[],
                        }
                    )
                }
                
            })
        })
    },
    handleFirstTag(tags){
        console.log({tags},tags[0].tagnames);
        return tags[0].tagnames;
    },
    handleTag(indexBlock,indexTag,block,tagnames){
        console.log(indexBlock,indexTag,block,{tagnames});

        getIndustry().then((res) => {
            console.log({res});
            this.industryTags = [];
            res.data.splice(this.taga1,this.taga2).map((item,index) => {
                // let icon = require('@/assets/images/industry/oceanEngineering.svg');
                // let iconHover = require('@/assets/images/industry/oceanEngineering.svg');
                let icon = this.baseURL + item.applicationClassPictureAddressBlack;
                let iconHover =this.baseURL + item.applicationClassPictureAddressLight;
                let iconname = item.applicationName;
                this.industryTags.push(
                    {
                        icon: icon,
                        iconHover: iconHover,
                        iconname: iconname,
                        tags:[],
                    }
                )
                console.log(1234,this.industryTags,item.applicationLaBleList,item.applicationLaBleList.length);

                if(item.applicationLaBleList.length != 0){
                    item.applicationLaBleList.map((item2,index2) => {
                        let tag = item2.applicationLaBleName;
                        let buttonType = index2==0 ? 'primary' : 'primary2';
                        this.industryTags[index].tags.push(
                            {
                                tag: tag,
                                buttonType:buttonType,
                                tagnames:[],
                            }
                        )
                        if(item2.applicationLaBleText != 0){
                            item2.applicationLaBleText.map((item3,index3) => {
                                this.industryTags[index].tags[index2].tagnames.push({tagname:item3.applicationLaBleContent})
                            })
                        }else{
                            this.industryTags[index].tags[index2].tagnames.push({tagname:null})
                        }
                    })
                }else{
                    this.industryTags[index].tags.push(
                        {
                            tag: null,
                            buttonType:null,
                            tagnames:[],
                        }
                    )
                }
                
            })
            this.industrys[indexBlock].tags[0].tagnames = this.industryTags[indexBlock].tags[indexTag].tagnames;
            this.industrys[indexBlock].tags.map((item,index) => {
                this.industrys[indexBlock].tags[index].buttonType = 'primary1';
            })
            this.industrys[indexBlock].tags[indexTag].buttonType = 'primary';
        })
    },
    mouseEnter(){
        this.industryActive = 'background: #3278FC';
        console.log(1234);
    },
    mouseLeave() {
        this.industryActive = '';
    },

    // 分类
    getCategoryNameList(){
        getCategoryName().then(res => {
            console.log('分类:',res);
            this.categoryNames = [];
            this.categoryNames.push({applicationClassId:0,applicationClassName:'全部'});
            res.data.map((item,index) => {
                this.categoryNames.push({applicationClassId:item.applicationClassId,applicationClassName:item.applicationClassName})
            })
        })
    },
    getCategoryList(){
        this.categorys = [
            {
                img: require('@/assets/images/category/category1.png'),
                categoryname:'电力覆冰试验室',
                industryname:'电力电器行业',
                icon:require('@/assets/images/category/icon.svg'),
            },
            {
                img: require('@/assets/images/category/category1.png'),
                categoryname:'电力装备试验环境室',
                industryname:'电力电器行业',
                icon:require('@/assets/images/category/icon.svg'),
            },
        ]

        getCategory().then(res => {
            console.log({res});

            this.categorys = [];
            res.data.map((item,index) => {
                let categoryname = item.applicationClassName;
                item.laboratoryProductList.map((item2,index2) => {
                    this.categorys.push(
                        {
                            id:item2.laboratoryClassId,
                            img:this.baseURL + item2.laboratoryClassPictureAddress,
                            // img: require('@/assets/images/category/category1.png'),
                            categoryname:categoryname,
                            industryname:item2.laboratoryClassName,
                            icon:require('@/assets/images/category/icon.svg'),
                        }
                    )
                })
                
            })
        })
    },
    handleCategory(row){
        console.log({row});
        if(row=='全部'){
            this.getCategoryList();
        }else{
            getCategory().then(res => {
                console.log({res});

                this.categorys = [];
                res.data.map((item,index) => {
                    if(row == item.applicationClassName){
                        let categoryname = item.applicationClassName;
                        item.laboratoryProductList.map((item2,index2) => {
                            this.categorys.push(
                                {
                                    img:this.baseURL + item2.laboratoryClassPictureAddress,
                                    // img: require('@/assets/images/category/category1.png'),
                                    categoryname:categoryname,
                                    industryname:item2.laboratoryClassName,
                                    icon:require('@/assets/images/category/icon.svg'),
                                }
                            )
                        })
                    }
                    
                    
                })
            })
        }
    },
    handleGotoDetail(id){
        console.log({id});
        this.$router.push({path:'/detail',query:{id:id,name:'category'}});
    },

    // 案例
    getCaseList(){
        this.cases = [
            {
                id:1,
                img: require('@/assets/images/case/case2.png'),
                title:'驱动电机环境模拟舱',
                subtitle:'驱动电机环境模拟舱主要用于电机的安全性、环境适应性以及防1231234腐能力等指标考核，在各种环境耦合工况下进行性能测试。',
                day:'05-04',
                year:'2022',
            },
            {
                id:2,
                img: require('@/assets/images/case/case2.png'),
                title:'驱动电机环境模拟舱',
                subtitle:'驱动电机环境模拟舱主要用于电机的安全性、环境适应性以及防1231234腐能力等指标考核，在各种环境耦合工况下进行性能测试。',
                day:'05-04',
                year:'2022',
            },
        ]

        getCase().then(res => {
            console.log({res});
            this.cases = [];
            res.data.map((item,index) => {
                this.cases.push({
                    id:item.caseDetailsId,
                    img:this.baseURL + item.projectCasePicture,
                    // img: require('@/assets/images/case/case2.png'),
                    title:item.projectCaseName,
                    subtitle:item.projectCaseDescribe,
                    day: moment(item.projectCaseTime).month() + '-' + moment(item.projectCaseTime).date(),
                    year:moment(item.projectCaseTime).year(),

                })
            })
            console.log(this.cases);

        })
    },
    handleGotoCaseDetail(id){
        console.log({id});
        this.$router.push({path:'/detail',query:{id:id,name:'case'}});
    },
  },
}
</script>
  
  <style lang="less" scoped>
.lunbo {
  width: 100%;
  height: 100%;
}
.el-button--primary{
    background: #3278FC !important;
    border-color:#fff !important;
}
.industryHoverChange{
    background: #3278FC !important;
    color: #fff;
}
.body_center_industry{
    background-color: #F4F4F4;
    // padding-top: 60px;
    // height: 77px;
    // margin-top: 200px;
    .title{
        margin: 60px 0 0 0 ;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #333333;
    }
    .subtitle{
        margin:10px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        color: #666666;
    }
    .switch{
        margin:40px 0 0 0;
        .button{
            width: 280px;
            height: 36px;
            border-radius: 18px;
        }
    }
    .list{
        margin:40px 0 80px 0;
        .block{
            height: 563px;
            background: #fff;
            border-style:solid;
            border-width: 0 1px 0 0;
            border-color: #f0f0f0;
            
            .icon{
                padding: 60px 0 0 0;
                // width: 100px;
                // height: 100px;
                .img{
                    width: 100px;height: 100px;
                }
            }
            .iconname{
                padding:10px 0 0 0;
                font-size: 20px;
                font-family: Noto Sans S Chinese;
                font-weight: 500;
                // color: #FFFFFF;
            }
            .tag{
                padding: 20px 0 0 0;
                .button{
                    // width: 80px;
                    height: 32px;
                    line-height: 0px;
                    border-radius: 20px;
                    margin: 0 0 10px 0;
                }
            }
            .tagpaddingname{
                padding:18px 0 0 0;
                .tagname{
                    padding:20px 0 0 0;
                }
            }
        }
    }
}
.categoryHoverChange{
    background: #3278FC !important;
    // color:#fff;
    width: 315px;
    height: 296px;
    // padding: 4px !important;
    // margin: 10px;
}
.el-button--text{
    color: #333333;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-button--text:focus, .el-button--text:hover{
    color:#3278FC !important;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-divider--horizontal{
    margin: 30px 0 10px 0;
}
.el-image {
    display: revert;
}
.el-divider--horizontal{
    margin:30px 0 9px 0 !important;
}
.el-col-6 {
    width: 23.4%;
}
.body_center_category{
    background-color: #fff;
    // height: 77px;
    // margin-top: 200px;
    margin:0px !important;
    .title{
        margin: 60px 0 0 0 ;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #333333;
    }
    .subtitle{
        margin:10px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        color: #666666;
    }
    .switch{
        margin:40px 0 0 0;
    }
    .categoryfooter{
        padding: 40px 0;
    }
    .list{
        // margin:40px 0 80px 0;
        margin:34px 0 0 0;
        .block{
            padding: 4px !important;
            margin: 10px;
            // width: 315px;
            height: 296px;
            // overflow: hidden;
            .img{
                // width: 307px;
                height: 194px;

                cursor: pointer;  
                transition: all 0.6s;  
            }
            // .img:hover{  
            //     transform: scale(1.1);  
            //     height: 174px;
            //     padding: 4px !important;
            // }  
            .bottom{
                margin: 30px 0 0 0;
                .bottomicon{
                    padding: 0 20px;
                    .categoryname{
                        display: flex;
                        // padding: 0 0 0 20px;

                        font-size: 16px;
                        font-family: Noto Sans S Chinese;
                        font-weight: 400;
                    }
                    .icon{
                        display: flex;
                        padding: 20px 0 0 0;
                        justify-content: end;
                        height: 31px;
                        .img{
                            width: 26px;
                            height: 15px;
                        }
                    }
                }
                
                
            }
            .divider{
                // padding: 0 0px;
                height: 41px !important;
                .button{
                    display: flex;
                    margin: 0 0 0 20px;
                    height: 24px;
                    line-height: 0;
                    border-radius: 12px;

                }
                
            }
        }
        
    }

}
.caseHoverChange{
    background: #3278FC !important;
    color:#fff !important;
}
.body_center_case{
    background-color: #F4F4F4;
    // height: 77px;
    // margin-top: 200px;
    .title{
        margin: 60px 0 0 0 ;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #333333;
    }
    .subtitle{
        margin:10px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        color: #666666;
    }
    .list{
        margin:40px 0 80px 0;
        .block{
            .left{
                // background: red;
                width: 49.5%;
                .lefttitle{
                    margin:65px 50px 0 0;
                    font-size: 20px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 500;
                    line-height: 34px;
                    color: #333333;
                    display: flex;
                    justify-content: flex-end;
                }
                .leftsubtitle{
                    margin: 30px 50px 0 100px;
                    font-size: 16px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 500;
                    line-height: 27px;
                    color: #666666;
                    display: flex;
                    justify-content: flex-end;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
                .lefttitleelse{
                    justify-content: left;
                    margin: 65px 50px 10px 50px;
                    // margin: 0px;
                }
                .leftsubtitleelse{
                    justify-content: left;
                    margin: 10px 50px 10px 50px;
                    // margin: 0px;
                }
            }
            .middle{
                width: 1%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .topvertical{
                    height: 65px;
                    border: 1px solid rgba(153,153,153,0.39);
                    width: 1px;
                }
                .dot{
                    width: 11px;
                    height: 11px;
                    background: #666666;
                    border-radius: 50%;
                    opacity: 1;
                }
                .footervertical{
                    height: 156px;
                    border: 1px solid rgba(153,153,153,0.39);
                    width: 1px;
                }
            }
            .right{
                // background: #3278FC;
                width: 49.5%;
                .img{
                    margin: 14px 0 10px 0;
                    width: 440px;
                    height: 200px;
                }
                .year{
                    margin:56px 0 0 50px;
                    font-size: 46px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 300;
                    // line-height: 78px;
                    color: #999999;
                    display: flex;
                }
                .day{
                    margin: 11px 0 0 55px;
                    font-size: 16px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    // line-height: 27px;
                    color: #999999;
                    display: flex;
                }
                .yearelse{
                    display: block;
                    padding: 0 50px 0 0;
                    text-align: end;
                }
                .dayelse{
                    display: block;
                    padding: 0 50px 0 0;
                    text-align: end;
                }
            }
        }
    }
}
.body_center_slogan{
    // background: url('@/assets/images/slogan/slogan.png');
    // background:url("../assets/slogan/slogan.jpg");
    background:url("../../assets/images/slogan/slogan.png");
    // width: 99.6vw;
    height: 484px;
    background-size:100% 100%;
    background-repeat: 'no-repeat';
    // padding: 60px 0 73px 0;
    padding-top: 60px;
    padding-bottom: 73px;
    .missionblack{
        background: #000;
        height: 267px;
        border-radius: 8px 8px 0 0;
        .missionimg{
            display: flex;
            justify-content: center;
            margin: 62px 0 0 0;
            .img{
                width: 140px;
                height: 90px;
            }
        }
        .missiontitle{
            margin: 21px 0 40px 0;
            font-size: 36px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            color: #FFFFFF;
        }
        
    }
    .missionwhite{
        background: #fff;
        padding: 20px 0;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #212122;
        border-radius: 0 0 8px 8px;
    }
    .cultureblack{
        background: #000;
        height: 267px;
        border-radius: 8px 8px 0 0;
        .cultureimg{
            display: flex;
            justify-content: center;
            margin: 62px 0 0 0;
            .img{
                width: 140px;
                height: 90px;
            }
        }
        .culturetitle{
            margin: 21px 0 40px 0;
            font-size: 36px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
    .culturewhite{
        background: #fff;
        padding: 20px 0;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #212122;
        border-radius: 0 0 8px 8px;
    }
    .aimblack{
        background: #000;
        height: 267px;
        border-radius: 8px 8px 0 0;
        .aimimg{
            display: flex;
            justify-content: center;
            margin: 62px 0 0 0;
            .img{
                width: 140px;
                height: 90px;
            }
        }
        .aimtitle{
            margin: 21px 0 40px 0;
            font-size: 36px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
    .aimwhite{
        background: #fff;
        padding: 20px 0;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #212122;
        border-radius: 0 0 8px 8px;
    }
}
</style>
  