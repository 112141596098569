import request from '@/util/request'

// 案例
export function getCarousel() {
    return request({
      url: '/ysbc/homepageManagement/getAllPicture',
      method: 'get'
    })
  }

  // 行业
  export function getIndustry() {
    return request({
      url: '/ysbc/applicationClass/GetAllApplicationCLass',
      method: 'get'
    })
  }

// 分类
export function getCategory() {
    return request({
      url: '/ysbc/applicationClass/GetLaboratoryList',
      method: 'post'
    })
  }

  // 查询应用行业名称做下拉框
  export function getCategoryName() {
    return request({
      url: '/ysbc/applicationClass/getApplicationName',
      method: 'get'
    })
  }

// 案例
export function getCase() {
    return request({
      url: '/ysbc/projectCase/getProjectCaseByTopping',
      method: 'get'
    })
  }