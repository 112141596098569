import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '@/views/Login.vue'
// import Shouye from '@/components/shouye.vue'
// import Chanpin from '@/components/chanpin.vue'
// import Jiejue from '@/components/jiejue.vue'
// import Guanyv from '@/components/guanyv.vue'
// import Lianxi from '@/components/lianxi.vue'

import Index from '@/views/index.vue'
import Home from '@/views/home/index.vue'
import Industry from '@/views/industry/index.vue'
import Category from '@/views/category/index.vue'
import Case from '@/views/case/index.vue'
import Contact from '@/views/contact/index.vue'
import Detail from '@/views/detail/index.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Login,
  //   redirect: '/shouye',
  //   children: [
  //     { path: '/shouye', component: Shouye },
  //     { path: '/chanpin', component: Chanpin },
  //     { path: '/jiejue', component: Jiejue },
  //     { path: '/guanyv', component: Guanyv },
  //     { path: '/lianxi', component: Lianxi }
  //   ]
  // },
  {
    path: '/',
    component: Index,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      { path: '/industry', component: Industry },
      { path: '/category', component: Category },
      { path: '/case', component: Case },
      { path: '/contact', component: Contact },
      { path: '/detail', component: Detail },
    ]
  }
]

const router = new VueRouter({
  // mode: 'hash', // 去掉url中的#  本地
  // mode: 'history', // 去掉url中的#  打包时
  routes
})

export default router

