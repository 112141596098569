import request from '@/util/request'

export function getCategory() {
    return request({
      url: '/ysbc/applicationClass/GetLaboratoryList',
      method: 'post'
    })
  }

  // 查询应用行业名称做下拉框
  export function getCategoryName() {
    return request({
      url: '/ysbc/applicationClass/getApplicationName',
      method: 'get'
    })
  }

  // 通过关键词查询
  export function getCategorybyKeyword(Keyword) {
    return request({
      url: '/ysbc/laboratoryProductClass/getLaboratoryProductByName?laboratoryName='+Keyword.laboratoryName,
      method: 'post',
    //   data:Keyword
    })
  }

  // 点击查询
  export function getClickCategory(query) {
    return request({
      url: '/ysbc/applicationClass/GetLaboratoryList',
      method: 'post',
      params:query
    })
  }