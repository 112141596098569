import request from '@/util/request'

// 通过id词查询(从项目分类跳转而来)
export function getCategoryDetail(id) {
    return request({
      url: '/ysbc/case/getLaboratoryClassCaseById?laboratoryClassId='+id,
      method: 'get',
    //   data:Keyword
    })
  }

  // 通过id词查询(从项目案例跳转而来)
export function getCaseDetail(id) {
  return request({
    url: '/ysbc/caseDetails/getCaseDetails?laboratoryClassId='+id,
    method: 'get',
  //   data:Keyword
  })
}