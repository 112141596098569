<template>
    <div>
        <el-row>
            <el-image class="topImg" :src="topImg" ></el-image>
        </el-row>
        
        <!-- 分类 -->
        <el-row class="body_center body_center_case" >
            <el-row class="search" >
                <el-col  class="searchleft"><el-link :underline="false" class="searchleftname" @click="handleAll">全部</el-link></el-col>
                <el-col  class="searchright">
                    <el-input placeholder="请输入关键词" v-model="inputValue" class="input-with-select" clearable @keyup.enter.native="submitForm">
                        <el-select v-model="selectValue" slot="prepend" placeholder="请选择"  clearable disabled>
                            <el-option v-for="(item,index) in selectOptions " :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select> 
                        <el-button slot="append" icon="el-icon-search" @click="submitForm"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <div class="bigdivider"></div>
            </el-row>
            <el-row>
                <el-col :span="0">
                    <el-row class="switch"  v-for="(itemTag,indexTag) in categoryNames" :key="indexTag" >
                        <el-row>
                            <el-button type="text" class="button" @click="handleCase(itemTag.applicationClassId)" >{{itemTag.applicationClassName}}</el-button>
                            <div class="casedivider"></div>
                        </el-row>
                    </el-row>

                </el-col>
                <el-col :span="24">
                    <el-row   class="list">
                        <el-row class="block" v-for="(block,indexBlock) in cases" :key="indexBlock"
                        :class="{'caseHoverChange':indexBlock==caseHoverIndex}"
                        @mouseenter.native="caseHoverIndex = indexBlock" 
                        @mouseleave.native="caseHoverIndex - 1" 
                        
                        >
                        <a @click="handleGotoDetail(block.id)" style="width:100%">
                            <el-col :span="6">
                                <el-image :src="block.img" class="img" ></el-image>
                            </el-col>
                            <el-col :span="14">
                                <el-row class="title" :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#333'}">{{block.title}}</el-row>
                                <el-row class="subtitle"  :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#666'}">{{block.subtitle}}</el-row>
                            </el-col>
                            <el-col :span="4">
                                <el-row class="day"  :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.day}}</el-row>
                                <el-row class="year"  :style="{color:indexBlock==caseHoverIndex ? '#fff' : '#999'}">{{block.year}}</el-row>
                            </el-col>
                            <el-row>
                                <div class="blockdivider" ></div>
                            </el-row>
                            <el-row>
                                <div class="gap" :style="{background:indexBlock==caseHoverIndex ? '#fff' : '#fff'}"></div>
                            </el-row>
                        </a>

                        </el-row>
                    </el-row>
                    <el-row class="casefooter"></el-row>
                </el-col>
            </el-row>
        </el-row>
    </div>
</template>

<script>
import {getCategoryName, getCase, getCasebyKeyword, getCasebyApplicationClassId} from '@/api/case';
import moment from 'moment'

export default {
    data(){
        return{
            // 分类
            topImg:require('@/assets/images/case/case.png'),
            cases:[],
            caseHoverIndex:0,
            selectOptions:[
                {label:"薛永航",value:0},
                {label:"严振瑞",value:1},
                {label:"陆凯",value:2},
                {label:"关键词",value:4},
            ],
            selectValue:4,
            inputValue:'',

            // 类的名字的列表
            categoryNames:[],

            url: process.env.VUE_APP_BASE_API,

        }
    },
    created() {
        this.menu();
        this.getCategoryNameList();
        this.getCaseList();
    },
    
    methods:{
        menu() {
            window.scrollTo(0, 0);
        },
        getCategoryNameList(){
            getCategoryName().then(res => {
                console.log(res);
                this.categoryNames = res.data;
            })
        },
        getCaseList(){
            // this.cases = [
            //     {
            //         id:1,
            //         img: require('@/assets/images/case/case2.png'),
            //         title:'驱动电机环境模拟舱',
            //         subtitle:'驱动电机环境模拟舱主要用于电机的安全性、环境适应性以及防1231234腐能力等指标考核，在各种环境耦合工况下进行性能测试。',
            //         day:'05-04',
            //         year:'2022',
            //     },
            //     {
            //         id:2,
            //         img: require('@/assets/images/case/case2.png'),
            //         title:'驱动电机环境模拟舱',
            //         subtitle:'驱动电机环境模拟舱主要用于电机的安全性、环境适应性以及防1231234腐能力等指标考核，在各种环境耦合工况下进行性能测试。',
            //         day:'05-04',
            //         year:'2022',
            //     },
            // ];
            getCase().then(res => {
                console.log({res});
                this.cases = [];
                res.data.map((item,index) => {
                    this.cases.push({
                        id:item.caseDetailsId,
                        img:this.url + item.projectCasePicture,
                        // img: require('@/assets/images/case/case2.png'),
                        title:item.projectCaseName,
                        subtitle:item.projectCaseDescribe,
                        day: moment(item.projectCaseTime).month() + '-' + moment(item.projectCaseTime).date(),
                        year:moment(item.projectCaseTime).year(),

                    })
                })
                console.log(this.cases);

            })
        },
        handleAll(){
            this.getCaseList();
        },
        handleCase(item){
            getCasebyApplicationClassId({applicationClassId:item}).then(res => {
                console.log({item,res});
                this.cases = [];
                res.data.map((item,index) => {
                        this.cases.push({
                            id: item.caseDetailsId,
                            // img: require('@/assets/images/case/case2.png'),
                            img:this.url + item.projectCasePicture,
                            title:item.projectCaseName,
                            subtitle:item.projectCaseDescribe,
                            day: moment(item.projectCaseTime).month() + '-' + moment(item.projectCaseTime).date(),
                            year:moment(item.projectCaseTime).year(),

                        })
                    
                })
                console.log(this.cases);

            })

        },
        submitForm(){
            console.log(this.selectValue,this.inputValue);
            getCasebyKeyword({projectCaseName:this.inputValue}).then(res => {
                console.log(res);
                this.cases = [];
                res.data.map((item,index) => {
                    this.cases.push({
                        id:item.caseDetailsId,
                        img:this.url + item.projectCasePicture,
                        // img: require('@/assets/images/case/case2.png'),
                        title:item.projectCaseName,
                        subtitle:item.projectCaseDescribe,
                        day: moment(item.projectCaseTime).month() + '-' + moment(item.projectCaseTime).date(),
                        year:moment(item.projectCaseTime).year(),

                    })
                })
            })
        },
        handleGotoDetail(id){
            console.log({id});
            this.$router.push({path:'/detail',query:{id:id,name:'case'}});
        }
    }
}
</script>

<style lang="less" scoped>
::v-deep .el-select .el-input {
    width: 130px;
  }
  ::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
.caseHoverChange{
    background: #3278FC !important;
    color:#fff !important;
}
.el-button--primary{
    background: #3278FC !important;
    border-color:#fff !important;
}
.el-button--text{
    color: #333333;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-button--text:focus, .el-button--text:hover{
    color:#3278FC !important;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-divider--horizontal{
    margin: 30px 0 10px 0;
}
.el-image {
    display: revert;
}
.el-divider--horizontal{
    margin:30px 0 9px 0 !important;
}
.el-col-8 {
    width: 31.32%;
}
.body_center_case{
    background-color: #fff;
    // height: 77px;
    // margin-top: 200px;
    margin:0px !important;
    .search{
        display: flex;
        .searchleft{
            display: flex;
            margin: 60px 0 40px 0;
            .searchleftname{
                font-size: 30px;
                font-family: Noto Sans S Chinese;
                font-weight: 500;
                color: #333333;
                display:none;
            }
        }
        .searchright{
            display: flex;
            margin: 60px 0 40px 0;
            width: 600px;
        }
    }
    .bigdivider{
        border: 1px solid #E5E5E5;
    }
    
    .switch{
        display: flex;
        margin:32px 0 0 0;
        .button{
            display: flex;
            margin:0 34px;
            font-size: 20px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            line-height: 0;
            // color: #333333;
        }
        .casedivider{
            margin:20px 0 0 0;
            border: 1px solid #E5E5E5;
            width: 200px;

        }
    }
        
    .casefooter{
        padding: 40px 0;
    }
    .list{
        margin:40px 0 0 0;
        // margin:34px 0 0 0;
        .block{
            display: flex;
            .img{
                margin:20px;
                width: 282px;
                height: 176px;
            }
            .title{
                display: flex;
                font-size: 20px;
                font-family: Noto Sans S Chinese;
                font-weight: 700;
                color: #333333;
                margin:38px 0 0 0;
            }
            .subtitle{
                display: flex;
                font-size: 16px;
                font-family: Noto Sans S Chinese;
                font-weight: 500;
                color: #666666;
                margin: 29px 0 0 0;
                text-align: left;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;

            }
            .day{
                display: flex;
                font-size: 46px;
                font-family: Noto Sans S Chinese;
                font-weight: 300;
                color: #999999;
                margin:32px 0 0 0;
            }
            .year{
                display: flex;
                font-size: 16px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: #999999;
                margin: 11px 0 0 10px;
            }
            .blockdivider{
                margin:216px 0 0 0;
                border: 1px solid #E5E5E5;
                // width: 1200px;
            }
            .gap{
                height: 21px;
            }
        }
        
    }

}
</style>