<template>
    <div class="dibu">
      <el-row :gutter="10">
        <el-col :span="7" class="dibuleft">
          <!-- <el-row>
            <el-col :span="24">
              <div class="dibu_logo">
                <img src="@/assets/images/dibulogo.png" class="dibulogo" alt="">
              </div>
            </el-col>
          </el-row> -->
          <el-row class="row1">
            <el-col :span="16" class="row1-2">
              <img src="@/assets/images/yiLogoFooter.svg"  alt="" class="img">
            </el-col>
          </el-row>
          <el-row class="row2">
            <!-- 企业文化 | 三本文化 -->
            <el-col>
              <span class="row2-1">企业文化 |</span>
              <span class="row2-2">三本文化</span>
            </el-col>
          </el-row>
          <el-row class="row3">
            <el-col :span="24" class="row3-1">本分｜本职｜本命 </el-col>
          </el-row>

        </el-col>
        <el-col :span="5">
          <el-row>
            <el-col :span="24">
              <div class="dibu_lianxi">联系我们</div>
            </el-col>
            <!-- <el-col :span="24">
              <div class="shouhou">联系电话1：010-62669934</div>
            </el-col>
            <el-col :span="24">
              <div class="chuanzhen">联系电话2：010-62669934</div>
            </el-col> -->
            <el-col v-for="(item,index) in teles" :key="index">
              <div class="shouhou">{{item.name}}：{{item.tele}}</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col >
              <div class="dibu_lianxi"></div>
            </el-col>
            <!-- <el-col :span="24">
              <div class="shouhou">西安总部：陕西省西安市高新区南三环辅道32号7栋</div>
            </el-col>
            <el-col :span="24">
              <div class="chuanzhen">武汉分公司：武汉东湖高新区光谷时代广场A座18层海鸥工厂</div>
            </el-col> -->
            <el-col  v-for="(item,index) in addresss" :key="index">
              <div class="shouhou">{{item.name}}：{{item.address}}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="fenge"></div>
      <div class="beian">
        Copyright © {{year}} 西安易盛百川科技有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index"><img :src="policeBadge" class="policeBadge"> 陕ICP备2022011939号-1</a>
      </div>
    </div>
</template>

<script>
import {getTeleAddress} from '@/api/dibu';
import moment from 'moment';

export default {
  data(){
    return {
      year:moment().year(), 
      policeBadge:require('@/assets/images/policeBadge.png'),
      teles:[
          {
            name:'联系电话1',
            tele:'010-62669934'
          },
          {
            name:'联系电话2',
            tele:'010-62669934'
          },
        ],
      addresss:[
          {
            name:'西安总部',
            address:'陕西省西安市高新区南三环辅道32号7栋',
          },
          {
            name:'武汉分公司',
            address:'武汉东湖高新区光谷时代广场A座18层海鸥工厂4',
          }
        ]
    }
  },
  created(){
    this.getTeleAddressData();
  },
  methods:{
    getTeleAddressData(){
      getTeleAddress().then(res => {
        console.log({res});
        this.teles = [];
        this.addresss = [];
        res.data.map((item,index) => {
          if(index%2==0){
            this.teles.push({name:item.companyInformationModelName,tele:item.companyInformationModelContent})
          }else{
            this.addresss.push({name:item.companyInformationModelName,address:item.companyInformationModelContent})
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.policeBadge{
  height: 12px;
  line-height: 14px;
  margin: 0px 2px 0 0;
}
// .dibulogo{
//   width: 218px;
//   height: 174px;
// }
.dibuleft{
  .row1{
    .row1-1{
      .row1-1-img{
          width: 58px;
          height: 58px;
          display: flex;
      }
    }
    .row1-2{
      font-size: 60px;
      font-family: Noto Sans S Chinese;
      font-weight: 700;
      display: flex;
      .img{
        width: 210px;
        height: 54px;
      }
    }
  }
  .row2{
    margin: 10px 0 0 0;
    .row2-1{
      font-size: 24px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      float: left;
      margin: 0px 10px 0 0;
    }
    .row2-2{
      font-size: 16px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      margin: 8px 0 0 10px;
      
    }
  }
  .row3{
    font-size: 16px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    line-height: 30px;
    color: #FFFFFF;
    display: flex;
    margin: 10px 0 0 0;
    .row3-1{
      display: flex;
    }
  }
}
</style>