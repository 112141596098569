import Vue from 'vue'
import App from './App.vue'
import router from './router'
import less from 'less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui';
import './util/rem'
import BaiduMap from 'vue-baidu-map'

/**自己写的公共样式 */
import './assets/style/public.css'
/**公共字体图标 */
import './assets/font/iconfont.css'

Vue.use( less);
Vue.use(ElementUI);
Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'wIt2mDCMGWRIi2pioR8GZnfrhSKQHzLY'
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
