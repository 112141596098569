<template>
    <div>
        <el-row>
            <el-image class="topImg" :src="topImg" ></el-image>
        </el-row>
        
        <!-- 分类 -->
        <el-row class="body_center body_center_category" >
            <el-row class="search" >
                <el-col  class="searchleft"><el-link :underline="false" class="searchleftname" @click="handleAll">全部</el-link></el-col>
                <el-col  class="searchright">
                    <el-input placeholder="请输入关键词" v-model="inputValue" class="input-with-select" clearable @keyup.enter.native="submitForm">
                        <el-select v-model="selectValue" slot="prepend" placeholder="请选择"  clearable disabled>
                            <el-option v-for="(item,index) in selectOptions " :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select> 
                        <el-button slot="append" icon="el-icon-search" @click="submitForm"></el-button>
                    </el-input>
                </el-col>
            </el-row>
            <el-row>
                <div class="bigdivider"></div>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-row class="switch"  v-for="(itemTag,indexTag) in categoryNames" :key="indexTag" >
                        <el-row>
                            <el-button type="text" class="button" @click="handleCategory(itemTag.applicationClassId)">{{itemTag.applicationClassName}}</el-button>
                            <div class="categorydivider"></div>
                        </el-row>
                    </el-row>

                </el-col>
                <el-col :span="18">
                    <el-row :gutter="20"  class="list">
                        <el-col :span="8" v-for="(block,indexBlock) in categorys" :key="indexBlock" class="block"
                        :class="{'categoryHoverChange':indexBlock==categoryHoverIndex}"
                        @mouseenter.native="categoryHoverIndex = indexBlock" 
                        @mouseleave.native="categoryHoverIndex - 1" 
                        >
                            <a @click="handleGotoDetail(block.id)">
                                <el-row :style="{overflow:indexBlock==categoryHoverIndex?'hidden':'1'}">
                                    <el-image :src="block.img" class="img" fit="fill" :style="{transform: indexBlock==categoryHoverIndex ? 'scale(1.1)' : 'scale(1)' }"></el-image>
                                </el-row>
                                <el-row class="bottom">
                                    <el-row class="bottomicon">
                                        <el-col :span="20" class="categoryname" :style="{color:indexBlock==categoryHoverIndex ? '#fff' : '#333'}">
                                            <el-row>{{block.industryname}}</el-row>
                                        </el-col>
                                        <!-- <el-col :span="4" class="icon" :style="{paddingTop:indexBlock==categoryHoverIndex ? '1.25rem' : '0.425rem'}"> -->
                                        <el-col :span="4" class="icon" :style="{paddingTop:indexBlock==categoryHoverIndex ? '0.425rem' : '0.425rem'}">
                                            <img :src="block.icon"  class="img"/>
                                        </el-col>
                                    </el-row>
                                </el-row>
                                
                                <el-row class="divider">
                                    <!-- <el-button v-if="indexBlock==categoryHoverIndex" round type="primary" style="" class="button">{{block.categoryname}}</el-button> -->
                                    <div v-if="indexBlock==categoryHoverIndex" ></div>
                                    <el-divider v-else ></el-divider>
                                </el-row>
                            </a>
                        </el-col>
                    </el-row>
                    <el-row class="categoryfooter"></el-row>
                </el-col>
            </el-row>
            <!-- <el-row class="title">产品分类</el-row>
            <el-row class="subtitle">Product Category</el-row> -->
            
            
        </el-row>
    </div>
</template>

<script>
import {getCategoryName, getCategory, getCategorybyKeyword, getClickCategory} from '@/api/category';

export default {
    data(){
        return{
            // 分类
            topImg:require('@/assets/images/category/category.png'),
            categorys:[],
            categoryHoverIndex:0,
            selectOptions:[
                {label:"薛永航",value:0},
                {label:"严振瑞",value:1},
                {label:"陆凯",value:2},
                {label:"关键词",value:4},
            ],
            selectValue:4,
            inputValue:'',

            // 类的名字的列表
            categoryNames:[],

            url: process.env.VUE_APP_BASE_API,

        }
    },
    created() {
        this.menu();
        this.getCategoryNameList();
        this.getCategoryList();
    },
    
    methods:{
        menu() {
            window.scrollTo(0, 0)
        },
        getCategoryNameList(){
            getCategoryName().then(res => {
                console.log(res);
                this.categoryNames = res.data;
            })
        },
        getCategoryList(){
            getCategory().then(res => {
                console.log({res});

                this.categorys = [];
                res.data.map((item,index) => {
                    let categoryname = item.applicationClassName;
                    item.laboratoryProductList.map((item2,index2) => {
                        this.categorys.push(
                            {
                                id:item2.laboratoryClassId,
                                img:this.url + item2.laboratoryClassPictureAddress,
                                // img: require('@/assets/images/category/category1.png'),
                                categoryname:categoryname,
                                industryname:item2.laboratoryClassName,
                                icon:require('@/assets/images/category/icon.svg'),
                            }
                        )
                    })
                    
                })
                console.log(this.categorys);

            })
        },
        handleAll(){
            this.getCategoryList();
        },
        handleCategory(row){
            this.categorys = [];
            getClickCategory({applicationClassId:row}).then(res => {
                res.data.map(item => {
                    item.laboratoryProductList.map((item2,index2) => {
                        this.categorys.push(
                                {
                                    id:item2.laboratoryClassId,
                                    img:this.url + item2.laboratoryClassPictureAddress,
                                    // img: require('@/assets/images/category/category1.png'),
                                    categoryname:item2.laboratoryClassName,
                                    industryname:item2.laboratoryClassName,
                                    icon:require('@/assets/images/category/icon.svg'),
                                }
                            )
                    })
                })
            })
        },
        handleCategory2(row){
            console.log({row});
            getClickCategory({applicationClassId:row}).then(res => {
                console.log({res});

                this.categorys = [];
                res.data.map((item,index) => {
                    if(row == item.applicationClassName){
                        let categoryname = item.applicationClassName;
                        item.laboratoryProductList.map((item2,index2) => {
                            this.categorys.push(
                                {
                                    id:item2.laboratoryClassId,
                                    img:this.url + item2.laboratoryClassPictureAddress,
                                    // img: require('@/assets/images/category/category1.png'),
                                    categoryname:categoryname,
                                    industryname:item2.laboratoryClassName,
                                    icon:require('@/assets/images/category/icon.svg'),
                                }
                            )
                        })
                    }
                    
                    
                })
                console.log(1234,this.categorys);
            })

        },
        submitForm(){
            console.log(this.selectValue,this.inputValue);
            getCategorybyKeyword({laboratoryName:this.inputValue}).then(res => {
                console.log(res);
                this.categorys = [];
                res.data.map((item,index) => {
                    this.categorys.push({
                        id:item.laboratoryClassId,
                        img:this.url + item.laboratoryClassPicture,
                        // img: require('@/assets/images/category/category1.png'),
                        categoryname:item.applicationClassName,
                        industryname:item.laboratoryClassName,
                        icon:require('@/assets/images/category/icon.svg'),
                    })
                })
                console.log(1234,this.categorys);
            })
        },
        handleGotoDetail(id){
            console.log({id});
            this.$router.push({path:'/detail',query:{id:id,name:'category'}});
        }
    }
}
</script>

<style lang="less" scoped>
::v-deep .el-select .el-input {
    width: 130px;
  }
  ::v-deep.input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
.categoryHoverChange{
    background: #3278FC !important;
    // width: 315px;
    // height: 296px;
    // padding: 4px !important;
    // margin: 10px;
}
.el-button--primary{
    background: #3278FC !important;
    border-color:#fff !important;
}
.el-button--text{
    color: #333333;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-button--text:focus, .el-button--text:hover{
    color:#3278FC !important;
    font-size: 20px;
    font-family: Noto Sans S Chinese;
    font-weight: bold;
}
.el-divider--horizontal{
    margin: 30px 0 10px 0;
}
.el-image {
    display: revert;
}
.el-divider--horizontal{
    margin:30px 0 9px 0 !important;
}
.el-col-8 {
    width: 31.32%;
}
.body_center_category{
    background-color: #fff;
    // height: 77px;
    // margin-top: 200px;
    margin:0px !important;
    .search{
        display: flex;
        .searchleft{
            display: flex;
            margin: 60px 0 40px 0;
            .searchleftname{
                font-size: 30px;
                font-family: Noto Sans S Chinese;
                font-weight: 500;
                color: #333333;
            }
        }
        .searchright{
            display: flex;
            margin: 60px 0 40px 0;
            width: 600px;
        }
    }
    .bigdivider{
        border: 1px solid #E5E5E5;
    }
    .title{
        margin: 60px 0 0 0 ;
        font-size: 30px;
        font-family: Noto Sans S Chinese;
        font-weight: 500;
        color: #333333;
    }
    .subtitle{
        margin:10px 0 0 0;
        font-size: 20px;
        font-family: Arial;
        color: #666666;
    }
    .switch{
        display: flex;
        margin:32px 0 0 0;
        .button{
            display: flex;
            margin:0 34px;
            font-size: 20px;
            font-family: Noto Sans S Chinese;
            font-weight: 500;
            line-height: 0;
            color: #333333;
        }
        .categorydivider{
            margin:20px 0 0 0;
            border: 1px solid #E5E5E5;
            width: 200px;

        }
    }
        
    .categoryfooter{
        padding: 40px 0;
    }
    .list{
        // margin:40px 0 80px 0;
        margin:34px 0 0 0;
        .block{
            padding: 4px !important;
            margin: 10px;
            // width: 315px;
            height: 296px;
            // overflow: hidden;

            .img{
            // width: 307px;
                height: 194px;
                cursor: pointer;
                transition: all 0.6s;  
            }
            // .img:hover{
            //     transform: scale(1.1);
            //     height: 154px;

            //     // margin: 40px !important;
            // }  
            .bottom{
                margin: 30px 0 0 0;
                .bottomicon{
                    padding: 0 20px;
                    .categoryname{
                        display: flex;
                        // padding: 0 0 0 20px;

                        font-size: 16px;
                        font-family: Noto Sans S Chinese;
                        font-weight: 400;
                    }
                    .icon{
                        display: flex;
                        padding: 20px 0 0 0;
                        justify-content: end;
                        height: 31px;
                        .img{
                            width: 26px;
                            height: 15px;
                        }
                    }
                }
                
                
            }
            .divider{
                // padding: 0 0px;
                height: 41px !important;
                .button{
                    display: flex;
                    margin: 0 0 0 20px;
                    height: 24px;
                    line-height: 0;
                    border-radius: 12px;

                }
                
            }
        }
        
    }

}
</style>